@use '../utils/' as *;

.investors {
  background: rgba(43, 171, 225, 0.1);
  display: flex;
  justify-content: space-between;
  padding: em(20) em(80);
  &.-chart {
    background: var(--bg);
  }
  &.-how {
    display: block;
    background: url('../../assets/road.svg');
    position: relative;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
  }
  @include respond(phone) {
    padding: 0;
  }
  @include respond(tab-port) {
    justify-content: space-between;
    padding: 0 em(20);
    align-items: center;
  }
  @include respond(phone) {
    flex-direction: column;
    margin: 0;
    background: rgba(43, 171, 225, 0.1);

    margin: 0;
    align-items: normal;
  }

  .info {
    padding: em(20) em(40);

    @include respond(phone) {
      backdrop-filter: blur(1px);
      margin: 0;
    }
    @include respond(phone-small) {
      padding: em(20) em(20);
    }

    .title {
      font-family: var(--font-inter);
      font-size: 28px;
      font-weight: 500;
      line-height: em(19.683);
      &.-neon {
        color: var(--gray-10);
        padding: em(5);
        background-color: rgb(255, 153, 0);
        -webkit-animation: blink 800ms step-end infinite;
        animation: blink 800ms step-end infinite;
      }
    }
  }
  .details {
    font-family: var(--font-inter);
    font-size: 19.687px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.219px;
    ul {
      li {
        list-style: inside;
        margin: em(10) 0;
        p {
          display: inline;
        }
      }
    }

    @include respond(phone) {
      line-height: 30px;
    }

    .currencylist {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      div {
        width: 30px;
        height: auto;
        display: flex;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .steps {
      display: grid;
      gap: em(20);
      grid-template-columns: repeat(3, 1fr);
      @include respond(tab-land) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
        padding: em(0);
      }

      .stepbox {
        background: #4c93b1;
        background: radial-gradient(90deg, #4c93b1, #336e98, #1f4a7b);
        background: -webkit-radial-gradient(90deg, #4c93b1, #336e98, #1f4a7b);
        width: 350px;
        height: 200px;
        transition: transform 0.005s;

        @include respond(tab-port) {
          width: 300px;
        }
        @include respond(phone-small) {
          width: 250px;
        }

        display: flex;
        border-radius: em(15);
        padding: em(20) em(5);
        justify-content: space-around;
        @include respond(phone-small) {
          flex-direction: column;
          height: auto;
        }
        .action {
          display: flex;
          flex: 1;

          flex-direction: column;
          align-items: center;
          row-gap: em(5);
          @include respond(phone-small) {
            row-gap: 0;
          }
          padding: 0;

          .step {
            color: white;
            font-size: rem(14);
          }
          .logo {
            border-radius: em(50);
            display: flex;
            justify-content: center;
            align-items: center;
            width: em(60);
            height: em(60);
          }
        }
        .specs {
          display: flex;
          flex: 2;

          flex-direction: column;
          @include respond(phone-small) {
            align-items: center;
          }
          font-weight: 200;
          font-size: rem(14);
          color: white;
          font-family: var(--font-inter);
          div {
            font-weight: 400;
          }
          ul {
            li {
              list-style: outside;
              @include respond(phone-small) {
                list-style: inside;
              }
              margin-bottom: em(20);
            }
          }
        }
      }
    }
  }
}
.blocks {
  width: em(320);
  &.-builders {
    width: em(350);
    @include respond(tab-port) {
      width: em(260);
    }
  }
  @include respond(tab-port) {
    width: em(250);
  }
  @include respond(phone) {
    display: none;
  }
  margin-right: em(40);
  @include respond(tab-port) {
    margin-right: em(0);
    padding: em(20) em(40) em(20) em(0);
  }
  padding: em(20) em(80) em(20) em(0);
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-25 15:6:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-25 15:7:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-26 15:6:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}



@keyframes neon {
  from {
    text-shadow: 0 0 6px hsla(0, 89%, 64%, 0.92), 0 0 30px hsla(0, 89%, 64%, 0.34),
      0 0 12px hsla(0, 89%, 64%, 0.52), 0 0 21px hsla(0, 89%, 64%, 0.92),
      0 0 34px hsla(0, 89%, 64%, 0.78), 0 0 54px hsla(0, 89%, 64%, 0.92);
  }
  to {
    text-shadow: 0 0 6px hsla(0, 89%, 64%, 0.98), 0 0 30px hsla(0, 89%, 64%, 0.42),
      0 0 12px hsla(0, 89%, 64%, 0.58), 0 0 22px hsla(0, 89%, 64%, 0.84),
      0 0 38px hsla(0, 89%, 64%, 0.88), 0 0 60px hsla(0, 89%, 64%, 1);
  }
}

@-webkit-keyframes blink {
  50% {
    background-color: var(--danger-50);
  }
}
@keyframes blink {
  50% {
    background-color: var(--danger-50);
  }
}
