@keyframes ldio-dg66tpfuqso {
  0% {
    top: 73.92px;
    left: 73.92px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 40.04px;
    left: 40.04px;
    width: 67.76px;
    height: 67.76px;
    opacity: 0;
  }
}
.ldio-dg66tpfuqso div {
  position: absolute;
  border-width: 3.08px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-dg66tpfuqso 1.3157894736842106s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldio-dg66tpfuqso div:nth-child(1) {
  border-color: #1a90ff;
  animation-delay: 0s;
}
.ldio-dg66tpfuqso div:nth-child(2) {
  border-color: #1a90ff;
  animation-delay: -0.6578947368421053s;
}
.loadingio-spinner-ripple-67ewwazwocp {
  width: 154px;
  height: 154px;
  display: inline-block;
  overflow: hidden;
  /* background: #ffffff; */
  background-color: transparent;
}
.ldio-dg66tpfuqso {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-dg66tpfuqso div {
  box-sizing: content-box;
}

.noData_wrapper .noDataMsg {
  color: rgba(30, 116, 187, 1);
  font-size: 20px;
  font-weight: 600;
}
.noData_wrapper .noDataBtn {
  color: white;
  padding: 10px 25px;
  background-color: #1a90ff;
  cursor: pointer;
  border-radius: 25px;
}
