@use '../utils/' as *;

.navbar {
  position: sticky;
  z-index: 2000;
  top: 0;
  transition: 0.3s ease all;
  max-width: 100%;
  display: flex;
  height: 10vh;
  align-items: center;
  background: #1c92d2;
  justify-items: baseline;
  font-weight: normal;
  line-height: 2em;
  font-size: em(20);
  background: radial-gradient(ellipse at 35% 80%, #c3e7f6, #a9d0e5, #99b6ca);
  background: -webkit-radial-gradient(ellipse at 35% 80%, #c3e7f6, #a9d0e5, #99b6ca);

  &:hover {
    color: var(--text);
  }
  &.-standard {
    box-shadow: em(0) em(2) em(2) rgba(0, 0, 0, 0.25);
    background: var(--bg);
  }
  &.-landingpage {
    background: transparent;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    &--pos {
      position: sticky;
      background: radial-gradient(ellipse at 35% 80%, #c3e7f6, #a9d0e5, #99b6ca);
      background: -webkit-radial-gradient(ellipse at 35% 80%, #c3e7f6, #a9d0e5, #99b6ca);
    }
  }
  &.-aboutpage {
    background: radial-gradient(ellipse at 35% 80%, #c3e7f6, #a9d0e5, #99b6ca);
    background: -webkit-radial-gradient(ellipse at 35% 80%, #c3e7f6, #a9d0e5, #99b6ca);
    position: sticky;
    top: initial;
    right: initial;
    left: initial;
  }

  @include respond(tab-port) {
    border-bottom: none;
  }
  &__list {
    // margin: em(6) 0;
    display: flex;
    // list-style: none;5  height: 100%;
    // color: var(--main);
    padding: 0 0.5rem;
    position: relative;
    align-items: center;
    &.-landingpage,
    &.-aboutpage {
      letter-spacing: -em(0.2);
      font-weight: 500;
      font-size: rem(14);
      flex: 2;

      display: flex;
      justify-content: center;
      align-items: center;
      .navbar__list__item {
        &:hover {
          color: var(--main);
          cursor: pointer;
        }
      }
    }

    &.-main {
      letter-spacing: -em(0.2);
      font-weight: 500;
      font-size: rem(18);
      flex: 2;

      display: flex;
      .navbar__list__item {
        &:hover {
          color: var(--main);
          cursor: pointer;
        }
      }
    }
    &.-avatar {
      @include respond(tab-port) {
        display: none;
      }
    }
    & .dropdown {
      position: absolute;
      top: em(25);
      right: 0;
      background-color: var(--bg);
      width: em(250);
      border-radius: 0 0 em(5) em(5);

      & .navbar__link {
        color: var(--text);
        font-size: rem(18);

        transition: 300ms all ease-in-out;
        &__list {
          &__item {
            border-bottom: em(1) solid var(--gray-20);
            &:last-child {
              font-weight: bold;
            }
            &:hover {
              background-color: var(--main-10);
            }
          }
        }
      }

      overflow: hidden;
      transition: height 500ms ease;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      color: var(--main);
      &__list {
        &__item {
          border-bottom: em(1) solid var(--gray-20);
          padding: em(5) 0;
          &:last-child {
            font-weight: bold;
          }
          &:hover {
            background-color: var(--main-10);
          }
        }
      }
    }

    @include respond(phone) {
      padding: 0;
    }

    &__item {
      display: flex;

      justify-content: center;
      align-items: center;
      .icons_div {
        ul {
          display: flex;

          li {
            margin-left: 10px;
            a {
              // background-color: var(--main);
              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                // background-color: #eef4fb;
                padding: em(5);
                border-radius: em(5);
                transition: 300ms all ease-in-out;
                border: em(0.5) solid var(--main);
                &:hover {
                  // background-color: #738bae;
                  color: #1a90ff;
                  border: em(0.5) solid var(--secondary);
                }
              }
            }
          }
        }
      }
      &:hover {
        color: var(--main-80);
        cursor: pointer;
      }
      @include respond(tab-port) {
        display: none;
      }
    }
  }

  &__link {
    padding: 0 em(8);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    display: relative;
    &:hover {
      color: var(--main);
    }
  }

  &__logo {
    width: em(120);
    height: em(50);
    align-self: flex-start;

    img {
      width: 100%;
      height: 80%;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__button {
    display: none;

    @include respond(tab-port) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 65%;
      width: 4rem;
      cursor: pointer;

      &__component {
        display: none;

        @include respond(tab-port) {
          display: block;
          height: 0.2rem;
          width: 100%;
          background-color: var(--text);
        }
      }
    }
  }
}
.isMobileIcons {
  display: none;
  @include respond(phone) {
    display: block;
  }
  @include respond(tab-port) {
    display: block;
  }
  .icons_div {
    ul {
      display: flex;

      li {
        margin-left: 10px;
        a {
          // background-color: var(--main);
          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: #eef4fb;
            padding: em(3);
            border-radius: em(5);
            transition: 300ms all ease-in-out;
            border: em(0.5) solid var(--main);
            &:hover {
              // background-color: #738bae;
              color: #1a90ff;
              border: em(0.5) solid var(--secondary);
            }
          }
        }
      }
    }
  }
}
